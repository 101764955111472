"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedUtils = void 0;
class SharedUtils {
    static getDeliveryLeadTimeRangeFromList(leadTimes) {
        if (leadTimes.length === 0) {
            return;
        }
        const unitValues = {
            h: 1,
            d: 2,
            w: 3,
            m: 4
        };
        const resultValues = {
            min: 0,
            minUnit: 'h',
            max: 0,
            maxUnit: 'h'
        };
        leadTimes.forEach((leadTime) => {
            const split = leadTime.split('-');
            if (split.length >= 2) {
                const [minValue, minUnit] = /[hdwm]$/.test(split[0])
                    ? [parseInt(split[0].slice(0, -1), 10), split[0].slice(-1)]
                    : [parseInt(split[0], 10), 'w'];
                const [maxValue, maxUnit] = /[hdwm]$/.test(split[1])
                    ? [parseInt(split[1].slice(0, -1), 10), split[1].slice(-1)]
                    : [parseInt(split[1], 10), 'w'];
                if (unitValues[minUnit] > unitValues[resultValues.minUnit]) {
                    resultValues.minUnit = minUnit;
                    resultValues.min = minValue;
                }
                else if (unitValues[minUnit] === unitValues[resultValues.minUnit]) {
                    if (minValue > resultValues.min) {
                        resultValues.min = minValue;
                    }
                }
                if (unitValues[maxUnit] > unitValues[resultValues.maxUnit]) {
                    resultValues.maxUnit = maxUnit;
                    resultValues.max = maxValue;
                }
                else if (unitValues[maxUnit] === unitValues[resultValues.maxUnit]) {
                    if (maxValue > resultValues.max) {
                        resultValues.max = maxValue;
                    }
                }
            }
            else {
                const [value, unit] = /[hdwm]$/.test(split[0])
                    ? [parseInt(split[0].slice(0, -1), 10), split[0].slice(-1)]
                    : [parseInt(split[0], 10), 'w'];
                if (unitValues[unit] > unitValues[resultValues.minUnit]) {
                    resultValues.minUnit = unit;
                    resultValues.min = value;
                }
                else if (unitValues[unit] === unitValues[resultValues.minUnit]) {
                    if (value > resultValues.min) {
                        resultValues.min = value;
                    }
                }
                if (unitValues[unit] > unitValues[resultValues.maxUnit]) {
                    resultValues.maxUnit = unit;
                    resultValues.max = value;
                }
                else if (unitValues[unit] === unitValues[resultValues.maxUnit]) {
                    if (value > resultValues.max) {
                        resultValues.max = value;
                    }
                }
                if (!unit || unitValues[unit] >= unitValues[resultValues.maxUnit]) {
                    if (value > resultValues.max) {
                        resultValues.max = value;
                    }
                }
            }
        });
        if (resultValues.min === resultValues.max && resultValues.minUnit === resultValues.maxUnit) {
            return `${resultValues.min}${resultValues.minUnit}`;
        }
        return `${resultValues.min}${resultValues.minUnit}-${resultValues.max}${resultValues.maxUnit}`;
    }
    static getContentfulLocale(locale) {
        switch (locale) {
            case 'en-GB': return 'en-GB';
            case 'en-US': return 'en-US';
            case 'en-CA': return 'en-CA';
            case 'en-ZA': return 'en-ZA';
            case 'en-SG': return 'en-SG';
            case 'en-IN': return 'en-IN';
            case 'es-ES': return 'es-ES';
            case 'es-MX': return 'es-MX';
            case 'et-EE': return 'et-EE';
            case 'fr-BE': return 'fr-BE';
            case 'de-AT': return 'de-AT';
            case 'de-CH': return 'de-CH';
            case 'de-DE': return 'de-DE';
            case 'fr-CA': return 'fr-CA';
            case 'fr-CH': return 'fr-CH';
            case 'fr-FR': return 'fr-FR';
            case 'id-ID': return 'id-ID';
            case 'cs-CZ': return 'cs-CZ';
            case 'da-DK': return 'da-DK';
            case 'it-CH': return 'it-CH';
            case 'ja-JP': return 'ja-JP';
            case 'ko-KR': return 'ko-KR';
            case 'ms-MY': return 'ms-MY';
            case 'nl-BE': return 'nl-BE';
            case 'pl-PL': return 'pl-PL';
            case 'pt-BR': return 'pt-BR';
            case 'pt-PT': return 'pt-PT';
            case 'sv-SE': return 'sv-SE';
            case 'th-TH': return 'th-TH';
            case 'tr-TR': return 'tr-TR';
            case 'vi-VN': return 'vi-VN';
            case 'zh-CN': return 'zh-CN';
            case 'zh-TW': return 'zh-TW';
            case 'da-DK': return 'da-DK';
            default: return 'en-GB';
        }
    }
}
exports.SharedUtils = SharedUtils;
